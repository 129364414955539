<template>
    <div class="row no-gutter">
        <div class="offset-xl-0"></div>
        <div class="col-6 col-md-3 col-lg-1 col-xl-1 mb-2 text-center" v-for="kat in ponukaKamena" :key="kat.order">
            <a :href="$route.path + 'ponuka-kamena/' + kat.slug" class="item-wrap">
            <img
              class="img-fluid rounded-circle w-50"
              :src="kat.image"
              v-if="kat.image"
            />
            <img
              class="img-fluid rounded-circle w-50"
              src="/images/kamen/kategorie/no_image_cat.png"
              v-else
            />
            <p class="text-center">
              <strong>{{ kat.title }}</strong>
            </p>
          </a>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ponukaKamena: null,
        }
    },
    async mounted() {
    this.$axios
      .get("/data/ponuka-kamena.json")
      .then((response) => this.setData(response.data))
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    setData(data) {
      this.ponukaKamena = data;
    },
  }
}
</script>