<template>
  <div>
        <UvodPonuka />
    <section class="site-section" id="next-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5 mb-lg-0">
            <iframe
              src="https://www.youtube.com/embed/dC5-TbehkFs"
              style="width: 100%; height: 300px"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-md-6 ml-auto">
            <h2 class="section-title mb-3">čo robíme?</h2>
            <p class="lead">
              Naša firma sa zaoberá predajom a spracovaním prírodného kameňa, s
              už viac ako {{ rokovSkusenosti }} ročnými skúsenosťami.
            </p>
            <p>
              Pôsobíme v Banskej Bystrici časť Rakytovce, kde pre Vás ponúkame
              široký výber z kamenných obkladov, dlažieb, šľapákov, kameňa na
              murovanie, gabiónov, dekoračných skál a štrkov, celkovo u nás
              nájdete viac ako 600 druhov a výrobkov z prírodného kameňa. Dovoz
              kameňa zabezpečujeme hlavne po Banskobystrickom kraji, ale v
              prípade potreby nie je problém doviesť Váš materiál tam kam
              potrebujete, potom sa cena stanoví individuálne na základe počtu
              kilometrov.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="site-section about-us-section">
      <div class="container">
        <div class="row pt-0 site-section">
          <div class="col-md-6 align-self-center">
            <h2 class="section-title mb-4">prečo kameň?</h2>
            <p>
              Prírodný kameň má svoje osobité kúzlo a nadčasovú krásu, ktorú
              nikdy nenahradia umelé betónové imitácie. Pre svoju silu, odolnosť
              a trvanlivosť s ním nemôže súperiť žiadny iný materiál. Intenzívne
              oslovuje dva zmysly: zrak a hmat. Je atraktívny a dekoratívny,
              takže ho možno použiť nie len v exteriéry, ale čoraz viac sa
              používa aj v interiéry. Kameň sa upravuje ručným štiepaním a
              strojovým rezaním na platne rôznych tvarov a priemerov, triedi sa
              podľa hrúbok a druhov použitia.
            </p>
            <p>
              Jeho miesto je všade tam kde chceme zdôrazniť hodnotu, silu a
              pravdivosť prírodného materiálu
            </p>
          </div>
          <div class="col-md-6 ml-auto">
            <div class="block__16443 text-center d-block">
<!--              <span class="custom-icon mx-auto">-->
<!--                <img-->
<!--                  src="/images/andrej.jpg"-->
<!--                  alt="Image"-->
<!--                  class="img-fluid img-shadow rounded-circle w-25"-->
<!--                />-->
<!--              </span>-->
<!--              <h2 class="section-title">Andrej Dula</h2>-->
<!--              <p class="lead">Človek s ktorým sa stretnete</p>-->
              <iframe src="https://www.google.com/maps/embed?pb=!4v1658845990913!6m8!1m7!1sCAoSLEFGMVFpcE9GSUtMc0o1N1gya2hTN18tTW9EXzY2TmJWY1lfSnBCY3NGeFFF!2m2!1d48.6838308!2d19.1259639!3f61.745848012322156!4f-23.484484301810284!5f0.7820865974627469" style="border:0; width: 100%; height: 400px; margin-right: 1em;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
          <div class="col-md-12 align-self-center text-center mt-5">
            <h2 class="section-title mb-4">Podporujeme aj šport</h2>
          </div>
          <div class="col-md-4 align-self-center text-center mb-5">
            <h2 class="section-title mb-2">TJ Družstevník Mičiná</h2>
            <h3 class="section-title-sub mb-2 text-dark">Futbalový klub</h3>
            <img src="/images/podporovanie/tj_druzstevnik_micina.png" />
          </div>
          <div class="col-md-4 align-self-center text-center mb-5">
            <h2 class="section-title mb-2">Partizán BB</h2>
            <h3 class="section-title-sub mb-2 text-dark">Futbalový klub</h3>
            <img src="/images/podporovanie/partizan_bb.png" />
          </div>
          <div class="col-md-4 align-self-center text-center mb-5">
            <h2 class="section-title mb-2">Jakub Mišáni</h2>
            <h3 class="section-title-sub mb-2 text-dark">Pretekár na motorke</h3>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UvodPonuka from '@/components/UvodPonuka.vue';
export default {
  components : {
    UvodPonuka
  },
  metaInfo() {
    return {
      title: this.metaTagy.title,
      meta : [
        { name:"title", content: this.metaTagy.title },
        { name:"description", content: this.metaTagy.description },
        { name:"keywords", content: this.metaTagy.keywords },
        { property:"og:type", content:"website" },
        { property:"og:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"og:title", content: this.metaTagy.title },
        { property:"og:description", content: this.metaTagy.description },
        { property:"twitter:card", content:"summary_large_image" },
        { property:"twitter:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"twitter:title", content: this.metaTagy.title },
        { property:"twitter:description", content: this.metaTagy.description },
      ]
    }
  },
  data() {
    return {
      metaTagy: {
        title: "Predaj prírodného kameňa",
        keywords: "andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description:"Firma sa zaoberá predajom a spracovaním prírodného kameňa, s mnoho ročnými skúsenosťami. Pôsobí v Banskej Bystrici časť Rakytovce, kde Vám ponúkne široký výber sortimentu z prírodného kameňa.",
        urlPath: this.$router.fullpath
      },
      rokovSkusenosti: new Date().getFullYear() - 2009,
    };
  },
};
</script>